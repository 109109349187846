const Style = {

  base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase whitespace-nowrap text-xs rounded-lg',
  red: 'bg-red-400',
  blue: 'bg-blue-500',
  green: 'bg-green-600',
  orange: 'bg-orange-600',
  yellow: 'bg-amber-500',
  gray: 'bg-gray-500',
  amber: 'bg-amber-700'
}

export default Style;