import { Account } from 'views/account';
import { Profile } from 'views/account/profile';
import { Billing } from 'views/account/users/billing';
import { Upgrade } from 'views/account/upgrade';
import { Users } from 'views/account/users';
import { Password } from 'views/account/password';
import { TwoFA } from 'views/account/2fa';
import { APIKeyList } from 'views/account/apikey/list';
import { APIKeyEditor } from 'views/account/apikey/edit';
import Integrations from 'views/account/integrations';

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Your Account'
  },
  {
    path: '/account/profile',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Change Password'
  },
  {
    path: '/account/2fa',
    view: TwoFA,
    layout: 'app',
    permission: 'user',
    title: 'Manage 2FA'
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    scope: 'account.billing',
    title: 'Billing',
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'owner',
    scope: 'account.upgrade',
    title: 'Upgrade'
  },
  {
    path: '/users',
    view: Users,
    layout: 'app',
    permission: 'owner',
    scope: 'account.users',
    title: 'Users'
  },
  {
    path: '/account/integrations',
    view: Integrations,
    layout: 'app',
    permission: 'admin',
    title: 'Integrations'
  },
  // {
  //   path: '/account/apikeys',
  //   view: APIKeyList,
  //   layout: 'app',
  //   permission: 'developer', 
  //   title: 'API Keys'
  // },
  // {
  //   path: '/account/apikeys/create',
  //   view: APIKeyEditor,
  //   layout: 'app',
  //   permission: 'developer', 
  //   title: 'API Keys'
  // },
  // {
  //   path: '/account/apikeys/edit',
  //   view: APIKeyEditor,
  //   layout: 'app',
  //   permission: 'developer',
  //   title: 'API Keys'
  // },
]

export default Routes;