import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Paginate, Table } from "components/lib";
import { useState } from "react";

export default function WebhooksLogs() {
    const [offset, setOffset] = useState(0)
    const limit = 5

    const { data, isLoading } = useQuery({
        queryKey: ['integration', 'webhooks', 'logs', offset, limit],
        queryFn: () => axios.get(`/api/integration/webhooks/logs?offset=${offset}&limit=${limit}`).then(res => res.data),
    })

    return (
        <div className="grid gap-6">
            <Paginate
                offset={offset}
                limit={limit}
                total={data?.total}
                loading={isLoading}
                onChange={x => setOffset(x)}
            />

            <Table
                loading={isLoading}
                show={['name', 'status', 'status_code', 'event', 'date_created']}
                data={data?.logs}
                badge={{
                    col: 'status_code', color: 'red', condition: [
                        { value: 200, color: 'green' }
                    ]
                }}
            />
        </div>
    )
}