import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { AccountNav, Animate, Button, Card, Checkbox, Label, ModalContainer, MultiSelect, SubNav, TextInput, useView } from 'components/lib'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import WebhooksLogs from './webhooksLogs'
import { useState } from 'react'

export default function Integrations() {
    const view = useView()

    const [searchParams] = useSearchParams()
    const tab = searchParams.get('tab')

    const webhook = { name: '', url: '', events: [], voicemail_transcripts: false, hipaa_terms: false }
    const [modal, setModal] = useState({ open: false, ...webhook })
    const [unvalid, setUnvalid] = useState([])

    const navigate = useNavigate()

    const { data, refetch } = useQuery({
        queryKey: ['integration', 'webhooks'],
        queryFn: () => axios.get('/api/integration/webhooks').then(res => res.data),
    })

    const newMutation = useMutation({
        mutationKey: ['integration', 'webhook', 'new'],
        mutationFn: (data) => axios.post('/api/integration/new_webhook', data).then(res => res.data),
        onSuccess: () => {
            refetch()
            setModal({ open: false, ...webhook })
            view.notification.show('Webhook added successfully', 'success', true);
        },
        onError: (e) => view.handleError(e)
    })

    const testMutation = useMutation({
        mutationKey: ['integration', 'webhook', 'test'],
        mutationFn: (data) => axios.post(`/api/integration/webhook/${data.id}/test`).then(res => res.data),
        onSuccess: (data) => {
            view.notification.show(data.message, 'success', true);
        },
        onError: (e) => view.handleError(e)
    })

    const deleteMutation = useMutation({
        mutationKey: ['integration', 'webhook'],
        mutationFn: (data) => axios.delete(`/api/integration/webhook/${data.id}`).then(res => res.data),
        onSuccess: () => refetch()
    })

    const events = [
        { value: 'all', label: 'All' },
        { value: 'missed_call', label: 'Missed Call' },
        { value: 'text_message', label: 'Text Message' },
        { value: 'voicemail', label: 'Voicemail' },
        { value: 'callback', label: 'Callback' }
    ]

    const handleChange = (name, value) => {
        setModal(prev => {
            if (name === 'events') {
                if (value.find(e => e.value === 'all')) {
                    return { ...prev, [name]: [{ value: 'all', label: 'All' }] }
                }
                return { ...prev, [name]: value }
            }
            return { ...prev, [name]: value }
        })
        if (name === 'voicemail_transcripts' && !value) {
            return setUnvalid(prev => prev.filter(v => !['voicemail_transcripts', 'hipaa_terms'].includes(v)))
        }
        if (value) {
            setUnvalid(prev => prev.filter(v => v !== name))
        }
    }

    return (
        <>
            <AccountNav />

            <Animate>
                <Card title='Integrate Events'>
                    <SubNav
                        items={[
                            { label: 'Webhooks', action: () => navigate('/account/integrations') },
                            { label: 'Webhook Logs', action: () => navigate('/account/integrations/?tab=webhook_logs') }
                        ]}
                        active={tab || 'webhooks'}
                    />

                    {
                        tab !== 'webhook_logs' && (
                            <>
                                <Button
                                    text='New Webhook'
                                    action={() => setModal(prev => ({ ...prev, open: true }))}
                                    small
                                />

                                {
                                    modal.open && (
                                        <ModalContainer close={() => setModal({ open: false, ...webhook })} >
                                            <Card title='New Webhook' className='grid'>
                                                <TextInput
                                                    name={'name'}
                                                    label={'Name'}
                                                    required={true}
                                                    onChange={handleChange}
                                                    value={modal.name}
                                                    valid={unvalid.length && !unvalid.includes('name')}
                                                />
                                                <TextInput
                                                    name={'url'}
                                                    label={'URL'}
                                                    required={true}
                                                    onChange={handleChange}
                                                    value={modal.url}
                                                    valid={unvalid.length && !unvalid.includes('url')}
                                                    errorMessage={'Please enter a valid https URL'}
                                                />
                                                <MultiSelect
                                                    name={'events'}
                                                    label={'Events'}
                                                    required={true}
                                                    onChange={handleChange}
                                                    options={events}
                                                    valid={unvalid.length && !unvalid.includes('events')}
                                                    value={modal.events}
                                                    closeMenuOnSelect={false}
                                                    isOptionDisabled={o => {
                                                        if (modal.events.some(selected => selected.value === 'all')) {
                                                            return o.value !== 'all'; // Disable non-'All' options
                                                        }
                                                        return false;
                                                    }}
                                                />
                                                <Checkbox
                                                    name={'voicemail_transcripts'}
                                                    option={'Voicemail Transcripts'}
                                                    onChange={handleChange}
                                                    value={modal.voicemail_transcripts}
                                                    required={true}
                                                />
                                                {
                                                    modal.voicemail_transcripts && (
                                                        <div className='text-sm'>
                                                            <b>HIPAA Compliance Warning</b><br />
                                                            By including the voicemail transcript in the payload, you acknowledge that:<br />
                                                            <ul className='list-disc pl-4 mb-2'>
                                                                <li>This action is not HIPAA compliant.</li>
                                                                <li>Your use of this feature does not meet HIPAA standards for handling protected health information (PHI).</li>
                                                                <li>Our platform is not liable for any HIPAA violations or penalties resulting from this action.</li>
                                                            </ul>
                                                            <Checkbox
                                                                name={'hipaa_terms'}
                                                                option={'I confirm that I understand and accept these conditions before proceeding.'}
                                                                onChange={handleChange}
                                                                value={modal.terms}
                                                                required={true}
                                                                labelClassName={unvalid.length && unvalid.includes('hipaa_terms') ? 'text-red-400' : ''}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <div className='flex gap-2 mt-4'>
                                                    <Button
                                                        text='Save'
                                                        color='green'
                                                        small
                                                        disabled={unvalid.length}
                                                        loading={newMutation.isLoading}
                                                        action={() => {
                                                            let reqs = ['name', 'url']

                                                            if (modal.voicemail_transcripts) {
                                                                reqs = [...reqs, 'hipaa_terms']
                                                            }

                                                            let uu = reqs.filter(r => !modal[r])
                                                            if (!modal.events.length) {
                                                                uu = [...uu, 'events']
                                                            }
                                                            if (uu.length) {
                                                                return setUnvalid(uu)
                                                            }

                                                            return newMutation.mutate({
                                                                name: modal.name,
                                                                url: modal.url,
                                                                events: modal.events,
                                                                voicemail_transcripts: modal.voicemail_transcripts,
                                                                hipaa_terms: modal.hipaa_terms
                                                            })
                                                        }}
                                                    />
                                                    <Button
                                                        text='Cancel'
                                                        color='red'
                                                        small
                                                        action={() => setModal({ open: false, ...webhook })}
                                                    />
                                                </div>
                                            </Card>
                                        </ModalContainer>
                                    )
                                }

                                <div className="grid gap-6 mt-4">
                                    {
                                        data?.webhooks && data.webhooks.map(w => {
                                            const webhookEvents = events.filter(e => w.events.includes(e.value))
                                            return (
                                                <div className='grid gap-3 p-2 border rounded-md'>
                                                    <h3 className="text-lg font-medium">{w.name}</h3>
                                                    <TextInput
                                                        label={'URL'}
                                                        value={w.url}
                                                    />
                                                    <div>
                                                        <Label text='Events:' />
                                                        <div className="flex flex-wrap gap-2">
                                                            {
                                                                webhookEvents.map((e, i) => (
                                                                    <span>{e.label}{i < webhookEvents.length - 1 ? ',' : ''}</span>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Label text={`Voicemail Transcripts:`} />
                                                        <span>{w.voicemail_transcripts ? 'Yes' : 'No'}</span>
                                                    </div>
                                                    <div className='flex gap-2 flex-wrap'>
                                                        <Button
                                                            text='Test Webhook'
                                                            action={() => testMutation.mutate({ id: w.id })}
                                                            loading={testMutation.isLoading && testMutation.variables.id === w.id}
                                                            color='green'
                                                            small
                                                        />
                                                        <Button
                                                            text='Delete'
                                                            action={() => deleteMutation.mutate({ id: w.id })}
                                                            loading={deleteMutation.isLoading && deleteMutation.variables.id === w.id}
                                                            color='red'
                                                            small
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }

                    {
                        tab === 'webhook_logs' && (
                            <WebhooksLogs />
                        )
                    }
                </Card>
            </Animate>
        </>
    )
}
